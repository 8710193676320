import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from '../reducers/auth.reducer';
import * as fromPages from '../../pages/pages.reducer';
import * as fromApp from '../reducers/app.reducer';
import * as fromDrivers from '../../pages/store/driver/driver.reducer';
import * as fromBookings from '../../pages/store/booking/booking.reducer';
import * as fromHeatmap from '../../pages/store/heatmap/heatmap.reducer';
import * as fromPartners from '../../pages/store/partner/partner.reducer';
import * as fromChargers from '../../pages/store/charge-location/charge-location.reducer';
import * as fromChargePoints from '../../pages/store/charge-point/charge-point.reducer';
import * as fromTelemetries from '../../pages/store/telemetry/telemetry.reducer';
import * as fromQueues from '../../pages/store/queue/queue.reducer';
import * as fromZones from '../../pages/store/zone/zone.reducer';
import * as fromZoneBookings from '../../pages/store/zone-booking/zone-booking.reducer';
import { DriverState } from '../../shared/models';
import firebase from 'firebase/compat';
import { AppState } from '../reducers';
import { getTimezone } from 'countries-and-timezones';
import { OriginType } from 'src/app/shared/enums';
import * as polyline from '@mapbox/polyline';

export const selectApp = (state: AppState) => state.app;

export const selectDriversState = createFeatureSelector<fromDrivers.State>(fromDrivers.driversFeatureKey);
export const selectBookingsState = createFeatureSelector<fromBookings.State>(fromBookings.bookingsFeatureKey);
export const selectHeatmapState = createFeatureSelector<fromHeatmap.State>(fromHeatmap.heatmapsFeatureKey);
export const selectPartnersState = createFeatureSelector<fromPartners.State>(fromPartners.partnersFeatureKey);
export const selectQueuesState = createFeatureSelector<fromQueues.State>(fromQueues.queueFeatureKey);
export const selectZonesState = createFeatureSelector<fromZones.State>(fromZones.zoneFeatureKey);
export const selectZoneBookingsState = createFeatureSelector<fromZoneBookings.State>(fromZoneBookings.zoneBookingFeatureKey);
//export const selectChargersState = createFeatureSelector<fromChargers.State>(fromChargers.chargersFeatureKey);
export const selectChargersState = createFeatureSelector<fromChargers.State>(fromChargers.chargeLocationFeatureKey);
export const selectChargePointsState = createFeatureSelector<fromChargePoints.State>(fromChargePoints.chargePointFeatureKey);
export const selectTelemetriesState = createFeatureSelector<fromTelemetries.State>(fromTelemetries.telemetryFeatureKey);

export const selectMapBounds = createSelector(
    selectHeatmapState,
    state => state.mapBounds
);

export const selectZoneBookings = createSelector(
    selectZoneBookingsState,
    fromZoneBookings.getZoneBookings
);

export const selectZones = createSelector(
    selectZonesState,
    fromZones.selectAll
);

export const selectedZoneId = createSelector(
    selectZonesState,
    fromZones.getSelectedId
);

export const selectedZone = createSelector(
    selectZonesState,
    fromZones.getSelected
);

export const selectedZoneAggregate = createSelector(
    selectedZone,
    selectZoneBookings,
    (zone, bookings) => zone && ({...zone, bookings: bookings[zone.id] || []}) || null
);

export const selectMapZones = createSelector(
    selectZones,
    zones => ({
        type: "FeatureCollection",
        features: zones.map(zone => zone.feature.line)
    })
);

export const selectMapZonesLabels = createSelector(
    selectZones,
    selectZoneBookings,
    (zones, bookings) => ({
        type: "FeatureCollection",
        features: zones.map(zone => ({
            type: 'Feature',
            properties: {
                id: zone.id,
                name: zone.name,
                label: `${zone.name}${bookings[zone.id]?.length ? '\n(' + bookings[zone.id].length + ' FUB)' : ''}`
            },
            geometry: {
                type: 'Point',
                coordinates: zone.center
            }
        }))
    })
);

export const selectHistoric = createSelector(
    selectHeatmapState,
    state => state.historic || []
);

export const selectBottomSheetSize = createSelector(
    selectApp,
    (app: fromApp.State) => app.bottomSheetSize
);

export const selectDarkMode = createSelector(
    selectApp,
    (app: fromApp.State) => app.darkMode
);

export const selectSound = createSelector(
    selectApp,
    (app: fromApp.State) => app.sound
);

export const selectImpactEnabled = createSelector(
    selectApp,
    (app: fromApp.State) => app.impactEnabled
);

export const selectColorMode = createSelector(
    selectApp,
    (app: fromApp.State) => app.darkMode !== null ? (app.darkMode ? 'dark' : 'light') : 'dark'
);

export const selectUserAgent = createSelector(
    selectApp,
    (app: fromApp.State) => app.userAgent
);

export const selectIsNotMobileDevice = createSelector(
    selectUserAgent,
    userAgent => userAgent.device?.type !== 'mobile'
)

export const selectIsTesla = createSelector(
    selectUserAgent,
    (userAgent) => userAgent?.device?.vendor === 'Tesla'
);

export const selectIsSmartTV = createSelector(
    selectUserAgent,
    (userAgent) => userAgent?.device?.type === 'smarttv'
);

export const selectCanShowImpact = createSelector(
    selectIsTesla,
    selectIsSmartTV,
    selectIsNotMobileDevice,
    (tesla, smartTv, notMobile) => tesla || smartTv || notMobile || false
)

export const selectShowImpact = createSelector(
    selectImpactEnabled,
    selectCanShowImpact,
    (enabled, canShow) => enabled && canShow
)

export const selectShowZoom = createSelector(
    selectIsTesla,
    selectIsSmartTV,
    (tesla, smartTv) => tesla || smartTv || false
)

export const selectAuth = (state: AppState) => state.auth;

export const selectUser = createSelector(
    selectAuth,
    (state: fromAuth.State) => state.user
);

export const selectIdToken = createSelector(
    selectAuth,
    (state: fromAuth.State) => state.idToken
);

export const selectPages = (state: AppState) => state.pages;

export const selectIsAdmin = createSelector(
    selectUser,
    (user => user?.email?.endsWith('@viggo.com') || null)
);


export const selectUserEmail = createSelector(
    selectUser,
    (user => user?.email || null)
);

export const selectProductFeatures = createSelector(
    selectPages,
    (pages: fromPages.State) => pages.productFeatures
);

export const selectDispatchTags = createSelector(
    selectPages,
    (pages: fromPages.State) => pages.dispatchTags
);

export const selectAreas = createSelector(
    selectPages,
    (pages: fromPages.State) => pages.areas?.filter(area => !!area?.id) || null
);

export const selectActiveAreaId = createSelector(
    selectPages,
    (pages: fromPages.State) => pages.selectedAreaId || pages.driver?.area_id || pages.driver?.default_area_id
);

export const selectActiveArea = createSelector(
    selectAreas,
    selectActiveAreaId,
    selectPages,
    (areas, activeAreaId) => {
        return !!areas && !!activeAreaId && areas.find(x => x.id === activeAreaId) || null
    }
);

export const selectActiveAreaAndColorMode = createSelector(
    selectActiveArea,
    selectColorMode,
    (area, colorMode) => area && colorMode ? {
        area,
        colorMode
    } : null
);

export const selectActiveAreas = createSelector(
    selectAreas,
    selectActiveAreaId,
    (areas, activeAreaId) => !!areas && !!activeAreaId && ({
        options: areas,
        selected: activeAreaId
    }) || null
);

export const selectTMSMinutes = createSelector(
    selectPages,
    (pages: fromPages.State) => pages.tms || null
);

export const selectTMS = createSelector(
    selectTMSMinutes,
    selectMapBounds,
    (minutes: number, mapBounds) => ({
        type: 'FeatureCollection',
        features: minutes && mapBounds 
            && mapBounds[0][0] <= 12.653908356092083 && 12.653908356092083 <= mapBounds[1][0] 
            && mapBounds[0][1] <= 55.62936204602494 && 55.62936204602494 <= mapBounds[1][1] ? [{
            type: 'Feature',
            properties: {
                label: `${minutes}MIN`,
                icon: `cph_tms`
            },
            geometry: {
                type: 'Point',
                coordinates: [12.653908356092083, 55.62936204602494], 
            }
        }] : []
    })
);

export const selectHaulers = createSelector(
    selectPages,
    (pages: fromPages.State) => pages.driver?.partner_id_reference || null
);

export const selectHaulerIds = createSelector(
    selectHaulers,
    haulers => haulers || []
);

export const selectIsHauler = createSelector(
    selectHaulers,
    haulers => !!haulers?.length
);

export const selectIsHaulerOrAdmin = createSelector(
    selectIsHauler,
    selectIsAdmin,
    (isHauler, admin) => admin || isHauler
);

export const selectCanShowBusyCars = createSelector(
    selectIsAdmin,
    selectIsTesla,
    selectIsHauler,
    (admin, isTesla, isHauler) => admin || isTesla || isHauler
);

export const selectAuthenticated = createSelector(
    selectUser,
    (user: firebase.User) => !!user
);

export const selectUserId = createSelector(
    selectAuth,
    (state: fromAuth.State) => state.user?.uid
);

export const selectIsIdTokenCookieSet =  createSelector(
    selectAuth,
    (state: fromAuth.State) => state.cookieSet
);

export const selectEvents = createSelector(
    selectPages,
    (pages: fromPages.State) => pages.events
);

export const selectBookings = createSelector(
    selectBookingsState,
    fromBookings.selectAll
);

export const selectVehicleTypes = createSelector(
    selectPages,
    (pages: fromPages.State) => pages.vehicleTypes
);

export const selectVehicleTypesMap = createSelector(
    selectVehicleTypes,
    vehicleTypes => vehicleTypes ? Object.entries(vehicleTypes).reduce((p, [key, value]) => ({...p, [key]: value}), {}) : {}
);

export const selectDriver = createSelector(
    selectPages,
    (pages: fromPages.State) => pages.driver
);

export const selectDriverVehicle = createSelector(
    selectDriver,
    driver => driver?.vehicle || null
)

export const selectDriverSupportedFeatures = createSelector(
    selectDriver,
    driver => driver?.supported_features || []
)

export const selectAllDrivers = createSelector(
    selectDriversState,
    fromDrivers.selectAll
);

export const selectAllDriverEntities = createSelector(
    selectDriversState,
    fromDrivers.selectEntities
);

export const selectPrebookings = createSelector(
    selectBookings, 
    bookings => 
      bookings?.filter(b => !!b.pickup_at && b.status < 3 && b.origin_type != OriginType.ORIGIN_TYPE_CPH_AIRPORT_TMS)
)

export const selectOfferedPrebookings = createSelector(
    selectPrebookings,
    selectDriverSupportedFeatures,
    (bookings, feature_ids) => bookings.filter(booking => !booking.claim && (!booking.feature_ids?.length || booking.feature_ids?.every(feature => feature_ids.includes(feature))))
);

export const selectMybookings = createSelector(
    selectBookings, 
    selectUserId, 
    selectHaulerIds,
    (bookings, driverId, hauler_ids) => bookings.filter(b => (b.driver?.id === driverId || hauler_ids.includes(b.driver?.partner_id || b.partner_id_can_assign)))
);

export const selectMyPrebookings = createSelector(
    selectPrebookings,
    selectIsAdmin,
    selectUserId,
    (bookings, admin, driverId) => 
            admin ? [] : bookings?.filter(b => b.driver?.id === driverId)
);

export const selectValidBookings = createSelector(
    selectOfferedPrebookings,
    selectMybookings,
    selectBookings,
    selectIsAdmin,
    (bookings, myBookings, areaBookings, isAdmin) => isAdmin ? areaBookings : [...myBookings, ...bookings]);

export const selectHasUpcomingClaimedPrebooking = createSelector(
    selectBookings,
    selectUserId,
    (bookings, userId) => bookings.filter(booking => booking.driver?.id === userId && booking.status === 1 && !!booking.pickup_at && booking.pickup_at < Date.now() + 3600000).length > 0
)

export const selectBookingOffer = createSelector(
    selectUserId,
    selectBookings,
    (userId, bookings) =>  bookings.find(booking => booking.driver?.id === userId && booking.status === 2) || null
)

export const selectAreaTimeZoneId = createSelector(
    selectActiveArea,
    area => area?.time_zone_id || null
)

export const selectAreaTimeZone = createSelector(
    selectActiveArea,
    area => area && getTimezone(area.time_zone_id) || null
)

export const selectAreaTimeZoneOffset = createSelector(
    selectAreaTimeZone,
    timeZone => {
        return null;
    }
)

export const selectBookingEntities = createSelector(
    selectBookingsState,
    fromBookings.selectEntities
);

export const selectSelectedBookingId = createSelector(
    selectBookingsState,
    fromBookings.getSelectedId
);

export const selectCurrentTrip = createSelector(
    selectBookingsState,
    selectIsAdmin,
    selectUserId,
    (bookingState, admin, userId) => 
        (admin || userId === bookingState.trip?.driverId) && bookingState.trip?.status !== 'ForHire' ? bookingState.trip : null
);

export const selectSelectedBooking = createSelector(
    selectBookingEntities,
    selectSelectedBookingId,
    (bookings, bookingId) => bookingId && bookings[bookingId]
);

export const selectSelectedBookingDriver = createSelector(
    selectSelectedBooking,
    booking => booking?.driver?._id || null
);

export const selectDriverState = createSelector(
    selectPages,
    (state: fromPages.State) => state.driverState
);

export const selectDriverStatus = createSelector(
    selectDriverState,
    (driverState: DriverState) => driverState.status.type
);

export const selectEventStreamDisconnected = createSelector(
    selectPages,
    (state: fromPages.State) => state.socketStatus?.event !== 'connected'
);

export const selectPartners = createSelector(
    selectPartnersState,
    fromPartners.selectAll
);

export const selectChargePoints = createSelector(
    selectChargePointsState,
    fromChargePoints.selectAll
);

export const selectChargePointEntities = createSelector(
    selectChargePointsState,
    fromChargePoints.selectEntities
);

export const selectActiveChargePointId = createSelector(
    selectChargePointsState,
    fromChargePoints.getSelectedId
)

export const selectActiveChargePoint = createSelector(
    selectActiveChargePointId,
    selectChargePointEntities,
    (id, entities) => entities[id] || null
)

export const selectChargers = createSelector(
    selectChargersState,
    fromChargers.selectAll
);

export const selectActiveChargerId = createSelector(
    selectChargersState,
    fromChargers.getSelectedId
)

export const selectChargerEntities = createSelector(
    selectChargersState,
    fromChargers.selectEntities
)

export const selectActiveCharger = createSelector(
    selectActiveChargerId,
    selectChargerEntities,
    selectChargePoints,
    (selectedId, chargeLocationEntities, chargePoints) => selectedId && ({...chargeLocationEntities[selectedId], chargePoints: chargePoints.filter(cp => cp.locationId == selectedId)})
)

export const selectChargerFeatures = createSelector(
    selectChargers,
    selectMapBounds,
    (chargers, mapBounds) => ({
        type: 'FeatureCollection',
        features: chargers
            .filter(d => mapBounds[0][0] <= d.lng && d.lng <= mapBounds[1][0])
            .filter(d => mapBounds[0][1] <= d.lat && d.lat <= mapBounds[1][1])
            .map((d: any) => ({
            type: 'Feature',
            properties: {
                id: d.id,
                name: `${d.name || 'ViggoEnergy'} (${d.totalAvailable}/${d.total})`,
                icon: d.status === 'available' ? 'marker_charger' : 'marker_charger_unavailable'
            },
            geometry: {
                type: 'Point',
                coordinates: [d.lng, d.lat],
            }
        }))
    })
);

export const selectDatastudioUrl = createSelector(
    selectDriver,
    driver => driver?.datastudio_url || null
)

export const selectAllTelemetry = createSelector(
    selectTelemetriesState,
    fromTelemetries.selectAll
);

export const selectTelemetryEntities = createSelector(
    selectTelemetriesState,
    fromTelemetries.selectEntities
);

export const selectUserTelemetry = createSelector(
    selectTelemetryEntities,
    selectUserId,
    (telemetry, userId) => telemetry[userId] || null
)

export const selectUserDirection = createSelector(
    selectUserTelemetry,
    telemetry => telemetry && telemetry.direction > -1 ? telemetry.direction : null
)

export const selectDriverEntities = createSelector(
    selectDriversState,
    fromDrivers.selectEntities
);

export const selectInitialSelectedDriverId = createSelector(
    selectDriversState,
    fromDrivers.getSelectedId
);

export const selectSelectedDriverId = createSelector(
    selectDriversState,
    fromDrivers.getSelectedId
);

export const selectShowOnlyAvailableDrivers = createSelector(
    selectDriversState,
    fromDrivers.getShowOnlyAvailableDrivers
);

export const selectShowOfflineDrivers = createSelector(
    selectDriversState,
    fromDrivers.getShowOfflineDrivers
);

export const selectActiveDriverId = createSelector(
    selectSelectedDriverId,
    selectSelectedBookingDriver,
    (driver, bookingDriver) => bookingDriver || driver
)

export const selectActiveDriverTelemetry = createSelector(
    selectTelemetryEntities,
    selectActiveDriverId,
    (telemetry, userId) => telemetry[userId] || null
)

export const selectActiveDriver = createSelector(
    selectActiveDriverId,
    selectDriversState,
    (driverId, state) => state.entities[driverId]
)

export const selectDriverIdEta = createSelector(
    selectActiveDriverId,
    selectUserId,
    (activeDriver, userId) => userId && activeDriver === userId ? activeDriver : null
)

const carStatusMarkers = [
    'no_light',
    'no_light',
    'green',
    'yellow',
    'red'
]

export const selectUserIsSelectedDriver = createSelector(
    selectDriversState,
    fromDrivers.getSelected
);

export const selectMyDriver = createSelector(
    selectTelemetryEntities,
    selectSelectedDriverId,
    selectSelectedBooking,
    (driverEntities, selectedDriverId, booking) => (booking && booking.status <= 5 && booking.driver ? driverEntities[booking.driver._id] : driverEntities[selectedDriverId])
);

export const selectMyDriverPosition = createSelector(
    selectMyDriver,
    (driver) => driver && driver.id && driver.lat && driver.lng ? driver : null
);

export const selectAllHeatmap = createSelector(
    selectHeatmapState,
    fromHeatmap.selectAll
);

export const selectHeatmap = createSelector(
    selectAllHeatmap,
    selectIsAdmin,
    selectUserId,
    (heatmap, isAdmin, userId) =>
        isAdmin ? heatmap : heatmap.filter(h => !h.hidden || !h.hidden[userId])
);

export const selectSelectedHeatmapFeature = createSelector(
    selectHeatmapState,
    fromHeatmap.getSelected
);

export const selectAreaHeatmap = createSelector(
    selectHeatmapState,
    state => state.areas
);

export const selectAreaMarkers = createSelector(
    selectHeatmapState,
    state => state.markers
);

export const selectMarkerFeatures = createSelector(
    selectAreaMarkers,
    selectMapBounds,
    (markers, mapBounds) => ({
        type: 'FeatureCollection',
        features: markers
            .filter(d => mapBounds[0][0] <= d.geometry.coordinates[0] && d.geometry.coordinates[0] <= mapBounds[1][0])
            .filter(d => mapBounds[0][1] <= d.geometry.coordinates[1] && d.geometry.coordinates[1] <= mapBounds[1][1])
            .map(d => !d.properties.metro ? d : {
                ...d,
                properties: {
                    ...d.properties,
                    icon: 'marker_metro'
                }
            })
            .map((d: any) => ({
                type: 'Feature',
                ...d
        }))
    })
);

export const selectFlights = createSelector(
    selectEvents,
    events => events.flights
);

export const selectSubscriptions = createSelector(
    selectPages,
    (pages: fromPages.State) => pages.subscriptions
);

export const selectDriversOnShift = createSelector(
    selectAllDrivers,
    drivers => drivers.filter(driver => !!driver?.name)
);

export const selectDriverEta = createSelector(
    selectDriverEntities,
    selectActiveDriverId,
    (drivers, driver_id) => drivers[driver_id]?.eta || null
)

function offlineSince(meta_ts: number) {
    if (!meta_ts) return 'Offline';
    const diff = Date.now() - meta_ts;
    const minutes = Math.ceil(diff/1000/60);
    if (minutes > 120) {
        return `Offline\n${Math.round(minutes/60)} hours`
    }
    return `Offline\n${Math.ceil(minutes)} min`
}

export const selectAllOtherDrivers = createSelector(
    selectAllTelemetry,
    selectActiveDriverId,
    selectDriver,
    selectMapBounds,
    selectShowOnlyAvailableDrivers,
    selectShowOfflineDrivers,
    selectVehicleTypesMap,
    (drivers, selectedDriver, myDriver, mapBounds, showOnlyAvailableDrivers, showOfflineDrivers, vehicle_type_map) => mapBounds && ({
        type: 'FeatureCollection',
        features: drivers
            .filter(d => (myDriver.id === d.id || d.status > (showOfflineDrivers ? 0 : 1) || selectedDriver === d.id) && (!showOnlyAvailableDrivers || d.status === 2) && d.lat >= mapBounds[0][1] && d.lat <= mapBounds[1][1] && d.lat >= mapBounds[0][1] && d.lat <= mapBounds[1][1])
            .map((d: any) => ({
                type: 'Feature',
                properties: {
                    id: d.license_plate && d.id || undefined,
                    d: d.direction,
                    i: selectedDriver === d.id ? `blue${vehicle_type_map[d.vehicle_type_id]?.postfix || ''}` : `${carStatusMarkers[d.status]}${vehicle_type_map[d.vehicle_type_id]?.postfix || ''}`,
                    s: vehicle_type_map[d.vehicle_type_id]?.seats || undefined,
                    l: d.status === 1 ? offlineSince(d.meta_ts) : (d.license_plate ? `${d.name}\n${d.license_plate}` : undefined),
                    u: myDriver.id === d.id
                },
                geometry: {
                    type: 'Point',
                    coordinates: [d.lng, d.lat],
                }
            })
        )
    })
);

export const selectHistoricGeoJSON = createSelector(
    selectHistoric,
    (data) => ({
        type: 'FeatureCollection',
        features: data
            .map((d: any) => ({
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'Point',
                    coordinates: d,
                }
            })
        )
    })
);

export const selectActiveQueueId = createSelector(
    selectQueuesState,
    fromQueues.getSelectedId
)

export const selectQueueEntities = createSelector(
    selectQueuesState,
    fromQueues.selectEntities
);

export const selectActiveQueue = createSelector(
    selectActiveQueueId,
    selectQueueEntities,
    selectDriverEntities,
    (id, entities, drivers) => {
        const activeQueue = {...entities[id]};
        if (!activeQueue) {
            return null;
        }
        activeQueue.driversArr = !activeQueue.drivers ? [] : Object.entries(activeQueue.drivers).map((x: any) => ({
            name: drivers[x[0]]?.name,
            vehicle: drivers[x[0]]?.vehicle,
            ...x[1]
        })).sort((a, b) => a.joined_at < b.joined_at ? -1 : 1)
        activeQueue.driversArr = [...activeQueue.driversArr.filter(x => !!x.with_dropoff), ...activeQueue.driversArr.filter(x => !x.with_dropoff)]
        return activeQueue;
    }
)

export const selectActiveQueueGeoJSON = createSelector(
    selectActiveQueue,
    (queue) => ({
        type: 'FeatureCollection',
        features: queue?.queue_polygons ? [
            {
                properties: {
                    name: queue.name,
                    id: queue.id,
                    in_queue: queue.drivers.length,
                    fillColor: 'red',
                    opacity: .5
                },
                geometry: {
                    type: "MultiPolygon",
                    coordinates: queue.queue_polygons.map(x => [polyline.decode(x.polyline).map(x => [x[1], x[0]])])
                }
            }
        ] : []
    })
);