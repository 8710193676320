export enum MessageType {
    SUBSCRIBE,
    UNSUBSCRIBE,
    SET_VEHICLE_TYPES,
    DELETE_ZONE_BOOKINGS,
    SET_TELEMETRY,
    UPSERT_DRIVER_ETA_CHANGED,
    UPSERT_QUEUES,
    LOAD_ZONES,
    LOAD_QUEUES,
    LOAD_DRIVER_STATUS,
    UPSERT_DRIVER_INFO,
    SET_DRIVER,
    SET_LOCATION_OPTIONS,
    UPSERT_TELEMETRY,
    UPDATE_ZONES,
    LOAD_HEATMAP_AREAS,
    UPDATE_HEATMAP_AREAS,
    UPDATE_HEATMAP_MARKERS,
    LOAD_HEATMAP_MARKERS,
    UPSERT_DRIVER_STATUS,
    UPSERT_VEHICLE_TYPE,
    UPSERT_CHARGE_POINTS,
    LOAD_CHARGE_POINTS,
    UPSERT_CHARGE_LOCATIONS,
    LOAD_CHARGE_LOCATIONS,
    UPSERT_STOP_ETA_CHANGED,
    UPSERT_PICKUP_ETA_CHANGED,
    DELETE_BOOKINGS,
    DELETE_BOOKING,
    UPDATE_BOOKING,
    UPDATE_BOOKINGS,
    UPSERT_BOOKING,
    UPSERT_BOOKINGS,
    LOAD_BOOKINGS,
    SET_PRODUCT_FEATURES,
    SET_DISPATCH_TAGS,
    SET_AREAS,
    SET_AREA,
    SET_TMS,
    UPSERT_ZONE_BOOKINGS,
    LOAD_BOOKING,
    SET_STATS,
    DIGITAX_STATUS,
    DRIVER_CERTIFICATE_NUMBER,
    DIGITAX_LOCATION,
    CANCEL_TRIP,
    SET_POLYLINES,
    FETCH_POLYLINES
}

export enum Topic {
    TELEMETRY = 'telemetry',
    STATS = 'stats'
}